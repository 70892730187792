let url : string = null;
if(document.location.hostname == 'termine.optikmaurer.de') {
    url = 'https://termine.optikmaurer.de/backend';
} else {
    url = 'https://termine-optikmaurer.profi-area-net.de/backend';
}
export const CONTENTFLY_URL = url;
export const ADMIN_GROUP : string = 'ec1992f2-7327-11ea-9e3b-00ac10d71c00';
export const USER_GROUP: string = 'c0403111-bd22-11eb-94dd-00ac10d52c00';
export const API_TOKEN : string = 'a976dbd477c208523cc149907f6b9e69b7338940c02335683360add476ed644aefda16d3f6fe89bfb396892493cbb261c30b29a6a91a9c40d2dcaf4eec5eb2a9';

export const APP_DEBUG : boolean = false;

/*
* SETTINGS
*/
export const COMPARE_DATE_STRING = '1989-07-26';
export const VACATION_COLOR = '#999999';
export const ILLNESS_COLOR = '#ff9900';
export const EDUCATION_COLOR = '#00cc00';
export const OTHERS_COLOR = '#33ccff';

export const SHOW_OPENING_TIMES_IN_CALENDAR = true;
export const DEFAULT_CALENDAR_VIEW = 'week';
 
export const CONFIRM_TIME_CONFLICTS = true;

export const LISTVIEW_ITEM_LIMIT = 25;
export const ALLOW_APPOINTMENT_CALENDAR_VIEW = false;

// FRONTEND
export const APPOINTMENT_SERVICE_ID = "b6cc6510-99b4-11ea-9e3b-00ac10d71c00";
export const APPOINTMENT_SERVICE_TITLE = "Online-Shop";

// The offset of days your allowed to book [in days]
export const APPOINTMENT_BOOKING_OFFSET = 0;